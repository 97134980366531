import React, { Suspense, lazy } from 'react';
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from 'components/layout'
const SeguridadInformatica = lazy(() => import('sections/hero/Seguridad-Informatica.js'));
const GratisGuia = lazy(() => import('sections/services/GratisGuia.js'));
const GratisAsesoria = lazy(() => import('sections/services/GratisAsesoria.js'));
const ConsultoriaCiberseguridad = lazy(() => import('sections/hero/ConsultoriaCiberseguridad.js'));
const OffShore = lazy(() => import('sections/about/OffShore.js'));
const TestimonialsThree = lazy(() => import('sections/testimonials/TestimonialsThree.js'));
const ClientsOne = lazy(() => import('sections/clients/ClientsOne.js'));
const VascoLanding = lazy(() => import('sections/hero/VascoLanding.js'));
const Cita = lazy(() => import('sections/testimonials/Cita.js'));
const LandingVasco = lazy(() => import('sections/hero/LandingVasco.js'));
const FaqGuia = lazy(() => import('sections/services/FaqGuia.js'));
const Llamenos = lazy(() => import('sections/hero/Llamenos.js'));
const ContactCreative = lazy(() => import('sections/contact/ContactCreative.js'));
const Footer = lazy(() => import('sections/services/Footer.js'));


class Index extends React.Component {

  render() {

    // Datos estructurados para preguntas y respuestas
    const PasoaPaso = {
      "@context": "https://schema.org",
      "@type": "HowTo",
      "name": "Los 5 errores comunes en Seguridad de la Información y cómo evitarlos",
      "description": "Esta guía está diseñada para ayudarte a identificar estos errores y aprender a evitarlos, protegiendo así tu empresa de pérdidas financieras y daños a la reputación.",
      "step": [
        {
          "@type": "HowToStep",
          "name": "Error 1- Contraseñas débiles y gestión inadecuada de contraseñas",
          "itemListElement": [
            {
              "@type": "HowToDirection",
              "text": "Implementa políticas de contraseñas fuertes que incluyan una combinación de letras mayúsculas y minúsculas, números y caracteres especiales."
            },
            {
              "@type": "HowToDirection",
              "text": "Utiliza herramientas de gestión de contraseñas para almacenar y recordar contraseñas de manera segura."
            },
            {
              "@type": "HowToDirection",
              "text": "Requiere la actualización regular de contraseñas, al menos cada 90 días."
            }
          ]
        },
        {
          "@type": "HowToStep",
          "name": "Error 2- Falta de capacitación en seguridad para los empleados",
          "itemListElement": [
            {
              "@type": "HowToDirection",
              "text": "Realiza sesiones de capacitación regulares sobre seguridad cibernética y concienciación sobre phishing."
            },
            {
              "@type": "HowToDirection",
              "text": "Implementa simulaciones de phishing para evaluar y mejorar la capacidad de respuesta de los empleados."
            },
            {
              "@type": "HowToDirection",
              "text": "Fomenta una cultura de seguridad, donde se incentive a los empleados a reportar actividades sospechosas."
            }
          ]
        },
        {
          "@type": "HowToStep",
          "name": "Error 3- Falta de actualización de software y parches de seguridad",
          "itemListElement": [
            {
              "@type": "HowToDirection",
              "text": "Implementa un proceso de gestión de parches que garantice que todos los sistemas operativos y aplicaciones estén actualizados."
            },
            {
              "@type": "HowToDirection",
              "text": "Utiliza herramientas de administración de actualizaciones para automatizar el proceso y minimizar los riesgos."
            },
            {
              "@type": "HowToDirection",
              "text": "Programa auditorías de seguridad regulares para identificar y corregir vulnerabilidades."
            }
          ]
        },
        {
          "@type": "HowToStep",
          "name": "Error 4- No realizar copias de seguridad regularmente",
          "itemListElement": [
            {
              "@type": "HowToDirection",
              "text": "Implementa una estrategia de copias de seguridad que incluya copias de seguridad regulares y almacenaje seguro fuera del sitio."
            },
            {
              "@type": "HowToDirection",
              "text": "Realiza pruebas periódicas de restauración de datos para asegurarte de que las copias de seguridad sean efectivas."
            },
            {
              "@type": "HowToDirection",
              "text": "Considera utilizar soluciones de backup en la nube para garantizar la disponibilidad y recuperación rápida de los datos."
            }
          ]
        },
        {
          "@type": "HowToStep",
          "name": "Error 5- Ausencia de un plan de respuesta a incidentes",
          "itemListElement": [
            {
              "@type": "HowToDirection",
              "text": "Desarrolla un plan de respuesta a incidentes que detalle los pasos a seguir en caso de una violación de seguridad."
            },
            {
              "@type": "HowToDirection",
              "text": "Asigna roles y responsabilidades claras a los miembros del equipo de respuesta a incidentes."
            },
            {
              "@type": "HowToDirection",
              "text": "Realiza ejercicios de simulación de incidentes para evaluar la efectividad del plan y ajustar según sea necesario"
            }
          ]
        }
      ]
    }




    return (
      <div>
        <Helmet>
          <title>Asesoría GRATIS en Seguridad Informática | Pereira, Armenia</title>
          <meta name="description" content="Descarga la Guía Gratis sobre los 5 Errores comunes en seguridad de la información y agenda una asesoría sin costo con nuestros expertos. Medellin, Cali, Bogota." />
          <script type="application/ld+json">
            {JSON.stringify(PasoaPaso)}
          </script>
        </Helmet>
        <Layout>
          <Suspense fallback={<div>Cargando...</div>}>
            <SeguridadInformatica />
            <GratisGuia />
            <ConsultoriaCiberseguridad />
            <GratisAsesoria />
            <TestimonialsThree />
            <ClientsOne />
            <VascoLanding />
            <Cita />
            <LandingVasco />
            <OffShore />
            <FaqGuia />
            <Llamenos />
            <ContactCreative />
            <Footer />
          </Suspense>
        </Layout>
      </div>
    )
  }
}

export default Index

export const creativeTwoQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
      }
    }
  }
`